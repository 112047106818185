import { ref } from 'vue';
import ApiService from "@/_services/api.service";
import swal from "@/_helpers/swal";

import {UserBlackList} from "@/models/blacklisting/UserBlackList";
import {formatDateString} from "@/_helpers/date.helper";
import {UserBlacklistHistorique, UserBlacklistUser} from "@/models/blacklisting/UserBlacklistHistorique";

export async function getUsersBlackListed(
    itemsPerPage: number,
    page: number,
    criteria?: null|string,
    sort?: { fieldLabel: string; fieldValue: string; }
): Promise<[boolean, number, UserBlackList[]]>
{
    const errorMsg = 'Une erreur est survenue au chargement des utilisateurs black listés.';
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const usersBlackList = Array<UserBlackList>();
    const tableData = ref<Array<UserBlackList>>(usersBlackList);
    let params = {
        itemsPerPage: itemsPerPage,
        page: page,
        search: criteria,
    };

    if (criteria !== "") {
        params = { ...params, search: criteria };
    }

    if (sort !== undefined) {
        const { fieldValue, fieldLabel } = sort;
        params = {
            ...params,
            [fieldLabel]: fieldValue,
        };
    }

    try {
        const { data } = await ApiService.get('user_black_lists', {
            params: params,
            headers: { Accept: 'application/ld+json' },
        });

        if (!data || !data.data) {
            throw new Error(errorMsg);
        }

        totalRecords.value = data.data['hydra:totalItems'];
        data.data["hydra:member"].forEach((item: any) => {
            if (item) {
                const user = item.user || {};
                const company = user.company || {};

                tableData.value.push({
                    uuid: item.uuid,
                    name: `${user.firstname} ${user.lastname}`,
                    company: company.name || '',
                    email: user.email || '',
                    blockDate: item.listedAt ? formatDateString(item.listedAt) : "",
                    role: user.roles || [],
                    state: item.state,
                    active: item.active,
                    action: "Vers historique blacklist",
                });
            }
        });
    } catch (error) {
        console.error(error);
        swal.error(errorMsg);
    }

    isLoading.value = false;

    return [isLoading.value, totalRecords.value, tableData.value];
}

export async function getUserHistoriqueBlackList(userBlackListUuid: string): Promise<[boolean, UserBlacklistHistorique]>
{
    const errorMsg = "Une erreur est survenue au chargement des détails d'un utilisateurs black listé.";
    const isLoading = ref<boolean>(true);
    const historiqueBlacklist = ref<UserBlacklistHistorique>({
        user: {} as UserBlacklistUser,
        blacklists: [],
        comments: [],
        active: false,
        state: false,
        uuid: "",
    });

    try {
        const { data } = await ApiService.get("user_black_lists/" + userBlackListUuid);
        const blacklistData = data.data;
        const user = data.data.user;
        user.lastLoginAt = user.lastLoginAt ? formatDateString(user.lastLoginAt) : "Aucune connexion";
        user.updatedAt = user.updatedAt ? formatDateString(user.updatedAt) : "";
        historiqueBlacklist.value.user = user ? user : ({} as UserBlacklistUser);
        data.data.userBlackListDetailsId.forEach((blacklist: any) => {
            historiqueBlacklist.value.blacklists.push({
                uuid: blacklist.uuid,
                date: blacklist.createdAt ? formatDateString(blacklist.createdAt) : "",
                reason: blacklist.reason ? blacklist.reason : "",
                action: "",
            });
            historiqueBlacklist.value.comments.push({
                uuid: blacklist.uuid,
                date: blacklist.updatedAt ? formatDateString(blacklist.updatedAt) : "",
                comment: blacklist.comment ? blacklist.comment : "",
                action: "",
                createdBy: blacklist.createdBy ? `${blacklist.createdBy.firstname} ${blacklist.createdBy.lastname}` : "",
            });
        });
        historiqueBlacklist.value.active = blacklistData.active ? blacklistData.active : false;
        historiqueBlacklist.value.state = blacklistData.state ? blacklistData.state : false;
        historiqueBlacklist.value.uuid = blacklistData.uuid ? blacklistData.uuid : "";
    } catch (error) {
        console.error(error);
        swal.error(errorMsg);
    }

    isLoading.value = false;

    return [isLoading.value, historiqueBlacklist.value];
}

export async function editBlacklistState(
    blacklistUuid: string,
    blacklistDetailUuid: string,
    status: boolean,
    comment: string,
    currentUserAdminUuid: string
): Promise<boolean> {
    try {
        await ApiService.patch("user_black_lists", blacklistUuid, {
            active: status,
            state: true,
        }).then(async () => {
            await ApiService.patch("user_black_list_details", blacklistDetailUuid, {
                comment: comment,
                createdBy: "/api/users/" + currentUserAdminUuid,
            }).then(() => {
                return true;
            });
        });
        return true;
    } catch (error) {
        return false;
    }
}