import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/_services/jwt.service";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { ICredentialsLogin } from "@/models/login/ICredentialsLogin";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  }

  public static setAuthorization(): void {
    ApiService.vueInstance.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    this.setAuthorization();
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] = "application/json";
  }

  /**
   * @description send the GET HTTP request
   */
  public static query(
    resource: string,
    params?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    this.setHeader();
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   */
  public static get(
    resource: string,
    params?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    this.setHeader();
    return ApiService.vueInstance.axios.get(`${resource}`, params);
  }

  /**
   * @description set the POST HTTP request
   */
  public static post(resource: string, data: object): Promise<AxiosResponse> {
    this.setHeader();
    return ApiService.vueInstance.axios.post(`${resource}`, data);
  }

  /**
   * @description set the POST HTTP request to login google 2fa code first step
   */
  public static postToLogin2FaFirstStep(resource: string, data: ICredentialsLogin): Promise<AxiosResponse> {
      const axiosInstance = ApiService.vueInstance.axios.create();
      axiosInstance.interceptors.request.use((config) => {
          config.headers['Accept'] = 'application/json';
          config.headers['Redirect-Url'] = '/admin/dashboard';
          
          return config;
      }, (error) => {
          return Promise.reject(error);
      });
      
      return axiosInstance.post(`${resource}`, data);
  }

    /**
     * @description set the POST HTTP request to verify google 2fa code
     */
    public static postToVerifyGoogle2FaAuthCode(resource: string, code: string): Promise<AxiosResponse> {
        ApiService.vueInstance.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getPreToken()}`;
        ApiService.vueInstance.axios.defaults.headers.common["Accept"] = "application/json";
        
        return ApiService.vueInstance.axios.post(`${resource}`, {code: code});
    }

  /**
   * @description send the UPDATE HTTP request
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    this.setHeader();
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    this.setHeader();
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }
    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param data: any (for file upload)
     * @param headers: object
     * @returns Promise<AxiosResponse>
     */
    public static async putWithParams(resource: string, data: any, headers: object = {}): Promise<AxiosResponse> {
        this.setHeader();
        return ApiService.vueInstance.axios.put(resource, data, { headers });
    }


  /**
   * @description Send the DELETE HTTP request
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    this.setHeader();
    return ApiService.vueInstance.axios.delete(resource);
  }

  /**
   * @description Send the PATCH HTTP request
   */
  public static patch(
    resource: string,
    slug: string,
    data: any
  ): Promise<AxiosResponse> {
    this.setHeader();
    return ApiService.vueInstance.axios.patch(`${resource}/${slug}`, data);
  }

  /**
   * @description Send the PATCH WITHOUT SLUG HTTP request
   */
  public static patchWithoutSlug(
    resource: string,
    data: any
  ): Promise<AxiosResponse> {
    this.setHeader();
    return ApiService.vueInstance.axios.patch(`${resource}`, data);
  }
}


export default ApiService;
