import { ref } from "vue";
import swal from "@/_helpers/swal";
import moment from "moment";
import { ISortType } from "@/models/supplier/ISortType";
import { ISupplier } from "@/models/supplier/ISupplier";
import { ISupplierForDropdown } from "@/models/supplier/ISupplierForDropdown";
import ApiService from "@/_services/api.service";
import TagListOptions from "@/models/modules/formkit/TagListOptions";

export async function getSupplier(uuid: string): Promise<ISupplier> {
    let supplier = {} as ISupplier;
    try {
        await ApiService.get("suppliers/" + uuid).then((response) => {
            supplier = response.data.data;
        });
        return supplier;
    } catch (error: any) {
        const apiResponse = error.response.data;
        let message = "Une Erreur est surevenue";
        if (
            apiResponse.data &&
            null !== apiResponse.data.data &&
            "undefined" !== apiResponse.data.data &&
            apiResponse.data.data.detail
        ) {
            message = apiResponse.data.data.detail;
        } else {
            message = apiResponse.errors[0].message;
        }
        swal.error(message);
        return supplier;
    }
}

export async function getPaginatedSupplierForDropDown(
    itemsPerPage: number,
    page: number,
    criteria?: string,
    isActive?: boolean,
    orderByName = "asc"
): Promise<[number, ISupplierForDropdown[]]> {
    const totalSuppliersRecords = ref<number>(0);
    const suppliers = Array<ISupplierForDropdown>()
    const suppliersForDropDown = ref<Array<ISupplierForDropdown>>(suppliers);

    interface IParams { itemsPerPage: number; page: number; name?: string; isActive?: boolean; "order[name]"?: string }
    let params: IParams = {
        itemsPerPage: itemsPerPage,
        page: page,
        "order[name]": orderByName
    };
    if (criteria !== undefined && criteria !== '') {
        params = { ...params, name: criteria.trim() };
    }
    if (isActive !== undefined) {
        params = { ...params, isActive: isActive };
    }

    try {
        const { data } = await ApiService.get('suppliers', {
            headers: { Accept: 'application/ld+json' },
            params: params,
        });
        totalSuppliersRecords.value = data.data['hydra:totalItems'];
        const transformedSuppliers: ISupplierForDropdown[] = data.data['hydra:member']
            .map((item: { uuid: string, name: string }) => ({
                label: item.name,
                value: item.uuid,
            }));
        suppliersForDropDown.value.push(...transformedSuppliers);
    } catch (error) {
        swal.error();
    }

    return [totalSuppliersRecords.value, suppliersForDropDown.value];
}

export async function createSupplier(data: object): Promise<object> {
    await ApiService.post("suppliers", data)
        .then((response) => {
            data = response.data.data;
            swal
                .success("Vous venez de créer un fournisseur !", "")
                .then(function (results) {
                    if (results.isConfirmed) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                });
            return data;
        })
        .catch((error) => {
            const apiResponse = error.response.data;
            let message = "Une Erreur est surevenue";
            if (
                apiResponse.data &&
                null !== apiResponse.data.data &&
                "undefined" !== apiResponse.data.data &&
                apiResponse.data.data.detail
            ) {
                message = apiResponse.data.data.detail;
            } else {
                message = apiResponse.errors[0].message;
            }
            swal.error(message);
            return data;
        });
    return data;
}

export async function updateSupplier(
    uuid: string,
    data: object
): Promise<object> {
    await ApiService.patchWithoutSlug("suppliers/" + uuid, data)
        .then((response) => {
            data = response.data.data;
            swal
                .success("Vous venez d'éditer un fournisseur !", "")
                .then(function (results) {
                    if (results.isConfirmed) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                });
            return data;
        })
        .catch((error) => {
            const apiResponse = error.response.data;
            let message = "Une Erreur est surevenue";
            if (
                apiResponse.data &&
                null !== apiResponse.data.data &&
                "undefined" !== apiResponse.data.data &&
                apiResponse.data.data.detail
            ) {
                message = apiResponse.data.data.detail;
            } else {
                message = apiResponse.errors[0].message;
            }
            swal.error(message);
            return data;
        });
    return data;
}

export async function fetchSuppliers(
    itemsPerPage: number,
    page: number,
    criteria?: string,
    sort?: ISortType[],
    name?: string,
    isActive?: boolean
) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const suppliers = Array<ISupplier>();
    const tableData = ref<Array<ISupplier>>(suppliers);
    let params = {
        itemsPerPage: itemsPerPage,
        page: page,
        name: name,
        isActive: isActive,
    };

    if (criteria !== "") {
        params = { ...params, name: criteria };
    }

    if (isActive !== undefined) {
        params = { ...params, isActive: isActive };
    }

    if (sort !== undefined) {
        sort.forEach((item) => {
            params = {
                ...params,
                [item.fieldLabel]: item.fieldValue,
            };
        });
    }
    try {
        const { data } = await ApiService.get("suppliers", {
            params: params,
            headers: { Accept: "application/ld+json" },
        });
        totalRecords.value = data.data["hydra:totalItems"];
        data.data["hydra:member"].forEach((item, index: number) => {
            item.id = index;
            item.createdAt = moment(String(item.createdAt)).format("DD/MM/YYYY");
            item.createdBy = setCreatedBy(item);
            item.fees = setFees(item);
            tableData.value.push(item);
        });
    } catch (error) {
        swal.error();
    }

    isLoading.value = false;
    return [isLoading, totalRecords, tableData];
}

export async function fetchAllSuppliers() {
    const totalRecords = ref<number>(0);
    const suppliers = Array<ISupplier>();
    const tableData = ref<Array<ISupplier>>(suppliers);

    try {
        const { data } = await ApiService.get("all-suppliers", {
            headers: { Accept: "application/ld+json" },
        });
        totalRecords.value = data.data["hydra:totalItems"];
        data.data["hydra:member"].forEach((item, index: number) => {
            item.id = index;
            item.createdAt = moment(String(item.createdAt)).format("DD/MM/YYYY");
            item.createdBy = setCreatedBy(item);
            item.fees = setFees(item);
            tableData.value.push(item);
        });
    } catch (error) {
        swal.error();
    }

    return [totalRecords, tableData];
}

const setCreatedBy = (item): string => {
    if (item.createdBy && item.createdBy.firstname && item.createdBy.lastname) {
        return item.createdBy.firstname + " " + item.createdBy.lastname;
    }

    return "";
};

const setFees = (item): number | null => {
    return item.fees;
};

export async function searchSuppliers({ search, excludeApiSupplier = true }) {
    const apiSuppliers = [
        '969808c4-3420-4af6-805f-2bda1c49bec8', // Api promoparcs
        '6c1bd1b9-70b0-4366-bfe3-eaf291af3491', // api presse 
        '16f2466d-366b-4623-985d-0a8881fb7358', // api jackpot
        '6e65eafb-aba7-11ec-a19c-de1ca07d1001',  // api Cinéchèque
        '79c5e982-fc65-4345-8aff-39f4226e2b60' // api API Ogloba
    ];

    if (!search || search.length < 3) return [];

    const fetchSuppliers = await ApiService.get(`suppliers?name=${search || ''}&isActive=true`);
    const fetchedSuppliers = fetchSuppliers.data.data;

    const suppliers = fetchedSuppliers.reduce((result: TagListOptions[], supplier: ISupplier) => {
        if (!excludeApiSupplier || !apiSuppliers.includes(supplier.uuid)) {
            result.push({
                label: supplier.name,
                value: supplier.uuid
            });
        }
        return result;
    }, []);

    return suppliers;
}

export async function searchSupplierOptions({ search, page, hasNextPage, active = true }) {
    const [totalSuppliersRecordsForDropDown, suppliersForDropDown] =
        await getPaginatedSupplierForDropDown(5, page, search, active);
    const hasItems = suppliersForDropDown.length > 0;
    if (hasItems && page !== totalSuppliersRecordsForDropDown) {
        hasNextPage();
    }

    return suppliersForDropDown;
}

export const SUPPLIER_UUIDS = {
    JACKPOT: '16f2466d-366b-4623-985d-0a8881fb7358',
    PRESS: '6c1bd1b9-70b0-4366-bfe3-eaf291af3491',
    PROMO_PARC: '969808c4-3420-4af6-805f-2bda1c49bec8',
    CINE_CHEQUE: '6e65eafb-aba7-11ec-a19c-de1ca07d1001',
    OGLOBA: '79c5e982-fc65-4345-8aff-39f4226e2b60'
};