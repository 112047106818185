const PRE_ID_TOKEN_KEY = "pre_token" as string;
const ID_TOKEN_KEY = "token" as string;
const ID_REFRESH_TOKEN_KEY = "refresh_token" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description get pre token form localStorage
 */
export const getPreToken = (): string | null => {
    return window.localStorage.getItem(PRE_ID_TOKEN_KEY);
};

/**
 * @description get token form localStorage
 */
export const getRefreshToken = (): string | null => {
  return window.localStorage.getItem(ID_REFRESH_TOKEN_KEY);
};

/**
 * @description save pre token for 2fa second step into localStorage
 */
export const savePreToken = (token: string): void => {
    window.localStorage.setItem(PRE_ID_TOKEN_KEY, token);
};

/**
 * @description save token into localStorage
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description save refresh token into localStorage
 */
export const saveRefreshToken = (refresh_token: string): void => {
  window.localStorage.setItem(ID_REFRESH_TOKEN_KEY, refresh_token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

/**
 * @description remove pre token used for 2fa second step form localStorage
 */
export const destroyPreToken = (): void => {
    window.localStorage.removeItem(PRE_ID_TOKEN_KEY);
};

/**
 * @description remove refresh token form localStorage
 */
export const destroyRefreshToken = (): void => {
  window.localStorage.removeItem(ID_REFRESH_TOKEN_KEY);
};

const JwtService = {
  getToken,
  saveToken,
  destroyToken,
  getRefreshToken,
  saveRefreshToken,
  destroyRefreshToken,
  getPreToken,
  savePreToken,
  destroyPreToken
};

export default JwtService;