import { ICompaniesForDropdown } from '@/models/company/ICompaniesForDropdown';
import { ref } from 'vue';
import ApiService from '@/_services/api.service';
import swal from '@/_helpers/swal';

export async function getPaginatedCompanyForDropDown(
    itemsPerPage: number,
    page: number,
    criteria?: string,
    active?: boolean
): Promise<[number, ICompaniesForDropdown[]]> {
    const totalCompaniesRecords = ref<number>(0);
    const companies = Array<ICompaniesForDropdown>()
    const companiesForDropDown = ref<Array<ICompaniesForDropdown>>(companies);

    interface IParams { itemsPerPage: number; page: number; name?: string; active?: boolean; }
    let params: IParams = {
        itemsPerPage: itemsPerPage,
        page: page,
    };
    if (criteria !== undefined && criteria !== '') {
        params = { ...params, name: criteria.trim() };
    }
    if (active !== undefined) {
        params = { ...params, active: active };
    }

    try {
        const { data } = await ApiService.get('companies', {
            headers: { Accept: 'application/ld+json' },
            params: params,
        });
        totalCompaniesRecords.value = data.data['hydra:totalItems'];
        const transformedCompanies: ICompaniesForDropdown[] = data.data['hydra:member']
            .map((item: { uuid: string, name: string }) => ({
                label: item.name,
                value: item.uuid,
            }));
        companiesForDropDown.value.push(...transformedCompanies);
    } catch (error) {
        swal.error();
    }

    return [totalCompaniesRecords.value, companiesForDropDown.value];
}

export async function searchCompanyOptions({ search, page, hasNextPage }) {
    const [totalCompaniesRecordsForDropDown, companiesForDropDown] = await getPaginatedCompanyForDropDown(5, page, search, true);

    const hasItems = companiesForDropDown.length > 0;
    if (hasItems && page !== totalCompaniesRecordsForDropDown) {
        hasNextPage();
    }

    companiesForDropDown.sort((a, b) => a.label.localeCompare(b.label));

    return companiesForDropDown;
}