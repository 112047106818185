import { defineStore } from "pinia";
import { ref } from "vue";
import { User } from "@/models/user/User";
import ApiService from "@/_services/api.service";
import JwtService from "@/_services/jwt.service";
import router from "@/router";
import {ICredentialsLogin} from "@/models/login/ICredentialsLogin";

export const useAuthStore = defineStore("auth", () => {
  const errors = ref({});
  const user = ref<Partial<User>>({});
  const isUserAuthenticated = ref<boolean>(!!JwtService.getToken());
  const USER_UUID_STORAGE_ID = 'presenter';

  function setError(error) {
    errors.value = { ...error };
  }

    function setPreAuth(user) {
        isUserAuthenticated.value = true;
        user.value = user;
        errors.value = {};
        JwtService.savePreToken(user.token);
    }

  function setAuth(user) {
    isUserAuthenticated.value = true;
    user.value = user;
    errors.value = {};
    JwtService.saveToken(user.token);
    JwtService.saveRefreshToken(user.refresh_token);
    JwtService.destroyPreToken();
    saveUserUuid(user.uuid);
  }

  function purgeAuth() {
    isUserAuthenticated.value = false;
    user.value = {} as User;
    errors.value = [];
    JwtService.destroyToken();
    JwtService.destroyRefreshToken();
    JwtService.destroyPreToken();
    destroyUserUuid();
    router.push('/')
  }
  
  function login(credentials: ICredentialsLogin) {
    return ApiService.postToLogin2FaFirstStep("/login_check", credentials)
      .then(({ data }) => {
          if (!data.data
              || !data.data.roles
              || !data.data.roles.includes("ROLE_ADMIN")
              || !('is_white_listed' in data.data)
          ) {
              throw new Error(`not authorized.`);
          }
          
          if (true === data.data.is_white_listed
              && data.data.token
              && data.data.refresh_token) {
              setAuth(data.data);
          }

          if (false === data.data.is_white_listed) {
              setPreAuth(data.data);
          }

         return data;
      })
      .catch(({ response }) => {
          const errorMsg = response && response.errors && response.errors.length > 0
              ? response.errors[0].message
              : "Vos identifiants sont incorrects";
          console.log(errorMsg);
        setError(`not authorized.`);
        throw errors;
      });
  }

    function verifyGoogle2faCode(authCode: string) {
        return ApiService.postToVerifyGoogle2FaAuthCode("/verify_google_2fa", authCode)
            .then(({ data }) => {
                if (!data.data
                    || !data.data.roles
                    || !data.data.roles.includes("ROLE_ADMIN")
                    || !('is_2fa_code_valid' in data.data)
                ) {
                    throw new Error('Invalid code.');
                }

                if (true === data.data.is_2fa_code_valid) {
                    setAuth(data.data);
                }
                
                return data;
            })
            .catch(({ response }) => {
                const errorMsg = response && response.errors && response.errors.length > 0
                    ? response.errors[0].message
                    : "Une erreur inattendue s'est produite";
                console.log(errorMsg);
                setError('Invalid code.');
                throw errors;
            });
    }

    function get2FaQrCode() {
      const wrongQRCodeMsg = 'Invalid QR code.';
      return ApiService.get("/get/2fa/qr-code")
        .then(({ data }) => {
            if (!data.data || !data.data.qrCode) {
                throw new Error(wrongQRCodeMsg);
            }
            
            return data.data.qrCode.trim();
        })
        .catch(({ response }) => {
            const errorMsg = response && response.errors && response.errors.length > 0
                ? response.errors[0].message
                : "Une erreur inattendue s'est produite à la récupération du QR Code.";
            console.log(errorMsg);
            setError(wrongQRCodeMsg);
            throw errors;
        });
    }

    function enableGoogleAuthenticatorSecret() {
        const error = "Une erreur inattendue s'est produite lors de l'activation du secret de google authenticator.";
        return ApiService.get("/enable_google_2fa")
            .then(({ data }) => {
                return data.data.message;
            })
            .catch(({ response }) => {
                const errorMsg = response && response.errors && response.errors.length > 0
                    ? response.errors[0].message
                    : error;
                console.log(errorMsg);
                setError(error);
                throw errors;
            });
    }

  function logout() {
    purgeAuth();
  }

  function register(credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        setAuth(data.data);
      })
      .catch(({ response }) => {
        setError(response.data.errors);
      });
  }

  function forgotPassword(payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        setError({});
      })
      .catch(({ response }) => {
        setError(response.data.errors);
      });
  }

  function verifyAuth(payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("token/refresh", payload)
        .then(({ data }) => {
          setAuth(data.data);
        })
        .catch(({ response }) => {
          setError(response.data.data);
          setError(response.data.data);
          purgeAuth();
        });
    } else {
      purgeAuth();
    }
  }

  function getCurrentUserUuid(): string | null {
    return window.localStorage.getItem(USER_UUID_STORAGE_ID);
  }

  function saveUserUuid (userUuid: string): void {
    window.localStorage.setItem(USER_UUID_STORAGE_ID, userUuid);
  }

  function destroyUserUuid (): void {
    window.localStorage.removeItem(USER_UUID_STORAGE_ID);
  }

  return {
    errors,
    user,
    isUserAuthenticated,
    login,
    logout,
    register,
    forgotPassword,
    verifyAuth,
    verifyGoogle2faCode,
    get2FaQrCode,
    enableGoogleAuthenticatorSecret,
    getCurrentUserUuid
  };
});